import React from 'react';
import Privacy from '@/sections/privacy/Privacy';
import CustomPageWrapper from '../components/CustomPageWrapper/CustomPageWrapper';
import { graphql } from 'gatsby';
import Helmet from '../components/Helmet';

const PrivacyPage = () => {
  return (
    <CustomPageWrapper withNavbar={true}>
      <div className='bg-white'>
        <Privacy />
      </div>
    </CustomPageWrapper>
  );
};

export default PrivacyPage;

export const Head = () => {
  return <Helmet />;
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
