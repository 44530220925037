import React from 'react';
import '@/styles/privacy.css';

const Privacy = () => {
  return (
    <div className='bg-white pt-10 pb-12 privacy-container'>
      <div className='container mx-auto'>
        <div className='grid'>
          <div className='privacy-text text-secondary'>
            <h2 className='text-primary text-3xl text-center pb-8'>
              Privacy Policy
            </h2>
            <p>
              Thank you for your interest in Smarttie. As you visit our website
              and learn more about our company, please take a moment to review
              this Privacy Policy, which explains what information we collect on
              our website and how we use that information.
            </p>

            <p className='pt-4'>
              <strong className='text-black text-lg'>
                Information we collect
              </strong>
              <br />
              The information we collect on our website includes the following:
            </p>
            <ul>
              <li className='mt-1'>
                <strong className='text-black'>Information you submit:</strong>{' '}
                We collect information you submit directly via email when you
                click on our website links for{' '}
                <a href='mailto:info@smarttie.ca'>info@smarttie.ca</a>,{' '}
                <a href='mailto:hr@smarttie.ca'>hr@smarttie.ca</a>, or{' '}
                <a href='mailto:rh@smarttie.ca'>rh@smarttie.ca</a>.
              </li>
              <li className='mt-1'>
                <strong className='text-black'>Device information:</strong> We
                may collect device-specific information (such as your hardware
                model, operating system version, unique device identifiers, and
                mobile network information including phone number).
              </li>
              <li className='mt-1'>
                <strong className='text-black'>Log information:</strong> When
                you view content provided by Smarttie, we may automatically
                collect and store certain information in server logs. This may
                include:
                <ul>
                  <li>Internet protocol address</li>
                  <li>
                    Device event information such as crashes, system activity,
                    hardware settings, browser type, browser language, the date
                    and time of your request and referral URL
                  </li>
                  <li>Cookies that may uniquely identify your browser</li>
                </ul>
              </li>
              <li className='mt-1'>
                <strong className='text-black'>
                  Cookies and Local Storage:
                </strong>{' '}
                We use various technologies to collect and store information
                when you visit our website, and this may include sending one or
                more cookies or randomly generated identifiers to your device. A
                cookie is a small file containing a string of characters that is
                sent to your computer when you visit a website. Cookies may
                store user preferences and other information. You can reset your
                browser to refuse all cookies or to indicate when a cookie is
                being sent. However, some website features may not function
                properly without cookies. We may also collect and store
                information using mechanisms such as browser web storage
                (including HTML5) and application data caches.
              </li>
            </ul>

            <p className='text-lg pt-4'>
              <strong className='text-black '>
                How we use information we collect
              </strong>
            </p>
            <p>
              We use the information we collect from our website to develop,
              provide, maintain, protect, and improve our products and services,
              and to protect Smarttie and our users.
            </p>
            <p>
              When you contact Smarttie, we may keep a record of your
              communication to help solve any issues you might be facing. We may
              use your email address to inform you about our products or
              services, such as upcoming changes or improvements.
            </p>
            <p>
              We use information collected from cookies and other technologies,
              like pixel tags, to improve your user experience and the overall
              quality of our website. We will ask for your consent before using
              information for a purpose other than those that are set out in
              this Privacy Policy.
            </p>
            <p>
              We may process your personal information on a server located
              outside the country where you live.
            </p>

            <p className='text-lg pt-4'>
              <strong className='text-black'>Transparency and choice</strong>
            </p>
            <p>
              You may set your browser to block all cookies, including cookies
              associated with our website, or to indicate when a cookie is being
              set by us. However, some website features may not function
              properly if your cookies are disabled.
            </p>

            <p className='text-lg pt-4'>
              <strong className='text-black'>Information we share</strong>
            </p>
            <ul>
              <li className='mt-1'>
                <strong className='text-black'>With your consent:</strong> We
                will share personal information with companies, organizations,
                or individuals outside of Smarttie when we have your consent to
                do so. We require opt-in consent for the sharing of any personal
                information relating to sensitive personal information (i.e.,
                confidential medical facts, racial or ethnic origins, political
                or religious beliefs, or sexuality).
              </li>
              <li className='mt-1'>
                <strong className='text-black'>For external processing:</strong>{' '}
                We provide personal information to our affiliates or other
                trusted businesses or persons to process it for us, based on our
                instructions and in compliance with our Privacy Policy and any
                other appropriate confidentiality and security measures.
              </li>
              <li className='mt-1'>
                <strong className='text-black'>For legal reasons:</strong> We
                will share personal information with companies, organizations,
                or individuals outside of Smarttie if we have a good-faith
                belief that access, use, preservation, or disclosure of the
                information is reasonably necessary to:
                <ul>
                  <li>
                    Meet any applicable law, regulation, legal process, or
                    enforceable governmental request.
                  </li>
                  <li>
                    Enforce this Privacy Policy, including investigation of
                    potential violations.
                  </li>
                  <li>
                    Detect, prevent, or otherwise address fraud, security, or
                    technical issues.
                  </li>
                  <li>
                    Protect against harm to the rights, property, or safety of
                    Smarttie, our users, or the public as required or permitted
                    by law.
                  </li>
                </ul>
              </li>
            </ul>

            <p className='pt-6'>
              We may share aggregated, non-personally identifiable information
              publicly and with our partners. For example, we may share
              information publicly to show trends about general public interest
              in our company.
            </p>
            <p>
              If Smarttie is involved in a merger, acquisition, or asset sale,
              we will continue to ensure the confidentiality of any personal
              information and give affected users notice before personal
              information is transferred or becomes subject to a different
              privacy policy.
            </p>

            <p className='mt-1'>
              <strong className='text-black'>Application</strong>
            </p>
            <p>
              Our Privacy Policy applies to information we collect on our
              website (including via email by clicking on the links for{' '}
              <a href='mailto:info@smarttie.com.mx'>info@smarttie.com.mx</a>,{' '}
              <a href='mailto:hr@smarttie.com.mx'>hr@smarttie.com.mx</a>, or{' '}
              <a href='mailto:rh@smarttie.com.mx'>rh@smarttie.com.mx</a>).
            </p>
            <p>
              Our Privacy Policy does not apply to services offered by other
              companies or individuals, or other sites linked from our website.
              Our Privacy Policy does not cover the information practices of
              other companies and organizations who may advertise our products
              or services, and who may use cookies, pixel tags, and other
              technologies to serve and offer relevant ads.
            </p>

            <p className='mt-1'>
              <strong className='text-black'>Enforcement</strong>
            </p>
            <p>
              We regularly review our compliance with our Privacy Policy. When
              we receive formal written complaints, we will contact the person
              who made the complaint to follow up. We will work with the
              appropriate regulatory authorities, including local data
              protection authorities, to resolve any complaints regarding the
              transfer of personal data that we cannot resolve with our users
              directly.
            </p>

            <p className='mt-1'>
              <strong className='text-black'>Changes</strong>
            </p>
            <p>
              Any changes we may make to our Privacy Policy in the future will
              be posted on this page.
            </p>
            <p className='mt-4'>
              <strong className='text-black'>
                Privacy Policy Effective Date:
              </strong>{' '}
              Jan 1, 2020
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
